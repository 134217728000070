
import Routers from "./routers";

export default function App() {
  return (
    <h1>
      <Routers></Routers>
    </h1>
  );
}
